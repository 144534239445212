// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-menu {
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999 !important;
    opacity: 0;
    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    transform: translateX(-100vw);
}

.mobile-menu-container {
    width: 60vw;
    height: 100vh;
    background-color: #342864;
    padding: 2rem;
}

.mobile-menu.active {
    opacity: 1;
    transform: translateX(0);
}

.mobile-menu img {
    margin-bottom: 3rem;
}

.mobile-menu ul {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    list-style: none;
    margin-left: -2rem;
}

@media (max-width: 925px) {
    .mobile-menu {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/MobileNav/MobileNav.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,oCAAoC;IACpC,eAAe;IACf,OAAO;IACP,MAAM;IACN,uBAAuB;IACvB,UAAU;IACV,4CAA4C;IAC5C,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".mobile-menu {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    display: none;\r\n    background-color: rgba(0, 0, 0, 0.3);\r\n    position: fixed;\r\n    left: 0;\r\n    top: 0;\r\n    z-index: 999 !important;\r\n    opacity: 0;\r\n    box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.3);\r\n    transition: all 0.3s ease;\r\n    transform: translateX(-100vw);\r\n}\r\n\r\n.mobile-menu-container {\r\n    width: 60vw;\r\n    height: 100vh;\r\n    background-color: #342864;\r\n    padding: 2rem;\r\n}\r\n\r\n.mobile-menu.active {\r\n    opacity: 1;\r\n    transform: translateX(0);\r\n}\r\n\r\n.mobile-menu img {\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.mobile-menu ul {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 2rem;\r\n    list-style: none;\r\n    margin-left: -2rem;\r\n}\r\n\r\n@media (max-width: 925px) {\r\n    .mobile-menu {\r\n        display: block;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
