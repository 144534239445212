// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form-content {
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
}

.name-container {
    display: flex;
    align-items: center;
    grid-gap: 1.5rem;
}

form input,
form textarea {
    flex: 1 1;
    width: 100%;
    font-size: 0.9rem;
    color: #fff;
    background: #130f2a;
    border-radius: 0.6rem;
    border: 1.5px solid #6751b9;
    padding: 1rem;
}

form button {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
    border: 1.5px solid #a892fe;
    border-radius: 0.6rem;
    background: linear-gradient(90deg, #a892fe 0%, #8064e8 100%);
    padding: 0.7rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

form button:hover {
    color: #a892fe;
    background: transparent;
}

@media (max-width: 600px) {
    .name-container {
        flex-direction: column;
        align-items: flex-start;
    }

    form input,
    form textarea {
        padding: 0.7rem;
        border-radius: 0.3rem;
    }

    form button {
        font-size: 0.9rem;
        padding: 0.7rem;
        border-radius: 0.3rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/ContactForm/ContactForm.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;;IAEI,SAAO;IACP,WAAW;IACX,iBAAiB;IACjB,WAAW;IACX,mBAAmB;IACnB,qBAAqB;IACrB,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,2BAA2B;IAC3B,qBAAqB;IACrB,4DAA4D;IAC5D,eAAe;IACf,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI;QACI,sBAAsB;QACtB,uBAAuB;IAC3B;;IAEA;;QAEI,eAAe;QACf,qBAAqB;IACzB;;IAEA;QACI,iBAAiB;QACjB,eAAe;QACf,qBAAqB;IACzB;AACJ","sourcesContent":[".contact-form-content {\r\n    width: 100%;\r\n}\r\n\r\nform {\r\n    display: flex;\r\n    flex-direction: column;\r\n    grid-gap: 1.5rem;\r\n}\r\n\r\n.name-container {\r\n    display: flex;\r\n    align-items: center;\r\n    grid-gap: 1.5rem;\r\n}\r\n\r\nform input,\r\nform textarea {\r\n    flex: 1;\r\n    width: 100%;\r\n    font-size: 0.9rem;\r\n    color: #fff;\r\n    background: #130f2a;\r\n    border-radius: 0.6rem;\r\n    border: 1.5px solid #6751b9;\r\n    padding: 1rem;\r\n}\r\n\r\nform button {\r\n    font-size: 1rem;\r\n    font-weight: 500;\r\n    color: #fff;\r\n    border: 1.5px solid #a892fe;\r\n    border-radius: 0.6rem;\r\n    background: linear-gradient(90deg, #a892fe 0%, #8064e8 100%);\r\n    padding: 0.7rem;\r\n    transition: all 0.3s ease;\r\n    cursor: pointer;\r\n}\r\n\r\nform button:hover {\r\n    color: #a892fe;\r\n    background: transparent;\r\n}\r\n\r\n@media (max-width: 600px) {\r\n    .name-container {\r\n        flex-direction: column;\r\n        align-items: flex-start;\r\n    }\r\n\r\n    form input,\r\n    form textarea {\r\n        padding: 0.7rem;\r\n        border-radius: 0.3rem;\r\n    }\r\n\r\n    form button {\r\n        font-size: 0.9rem;\r\n        padding: 0.7rem;\r\n        border-radius: 0.3rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
