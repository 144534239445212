import React from 'react'
import './App.css'
import Navbar from './components/Navbar/Navbar'
import Me from './components/Me/Me'
import Skills from './components/Skills/Skills'
import Experience from './components/Experience/Experience'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'

const App = () => {

  return (
    <>
      <Navbar />
      <div className="container">
        <Me />
        <Skills />
        <Experience />
        <Contact />
      </div>
      <Footer />
    </>
  )
}

export default App