import React from 'react'
import './Me.css'

const Me = () => {
  return (
    <section id="home" className="me-container">
        <div className="me-content">
            <h2>Building IT Solutions That Matter</h2>
            <p>
                Passionate AI Developer | Transforming Ideas into
                User-Friendly and Performing Solutions
            </p>
        </div>

        <div className="me-images">
            <div>
                <div className="tech-icon">
                    <img src="./assets/images/python.png" alt="python" />
                </div>

                <img src="./assets/images/arne.png" alt="Arne" />
            </div>

            <div>
                <div className="tech-icon">
                    <img src="./assets/images/wordpress.png" alt="wordpress" />
                </div>
                <div className="tech-icon">
                    <img src="./assets/images/docker.png" alt="docker" />
                </div>
                <div className="tech-icon">
                    <img src="./assets/images/linux.png" alt="linux" />
                </div>
            </div>
        </div>

    </section>
  )
}

export default Me