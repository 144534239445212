export const SKILLS = [
    {
        title: "AI",
        icon: "./assets/images/ai.png",
        skills: [
            { skill: "Tensorflow", percentage: "65%" },
            { skill: "Vertex AI", percentage: "55%" },
            { skill: "Scikit-learn", percentage: "60%" },
            { skill: "OpenCV", percentage: "65%" }
        ],
    },
    {
        title: "Backend",
        icon: "./assets/images/backend.png",
        skills: [
            { skill: "Python", percentage: "70%" },
            { skill: "Django", percentage: "60%" },
            { skill: "Java", percentage: "65%" },
            { skill: "C#", percentage: "60%" }
        ],
    },
    {
        title: "Frontend",
        icon: "./assets/images/frontend.png",
        skills: [
            { skill: "Wordpress", percentage: "70%" },
            { skill: "CSS", percentage: "55%" },
            { skill: "React", percentage: "50%" },
        ],
    },
    {
        title: "Tools",
        icon: "./assets/images/tools.png",
        skills: [
            { skill: "Git", percentage: "85%" },
            { skill: "Visual Studio Code", percentage: "75%" },
            { skill: "Linux", percentage: "80%" },
            { skill: "Notion", percentage: "70%" },
            { skill: "Docker", percentage: "85%" },
        ],
    },
]

export const EXPERIENCE = [
    {
        title: "Intern at Infofarm",
        date: "April 2024 - June 2024",
        responsibilities: [
            "Using computer vision for quality control on tomatoes.",
            "Labeling images for a machine learning model.",
            "Making a frontend to upload images and see the results of the prediction.",
        ]
    },
    {
        title: "Student Python Developer at Farmdesk",
        date: "June 2023 - November 2023",
        responsibilities: [
            "Implementing different features in a Python Django Backend.",
            "Connecting external APIs with the application.",
            "Automatically create data based on changes in data in an external API.",
        ]
    },
    {
        title: "Student Software Engineer at Bogaerts Greenhouse Logistics",
        date: "February 2023 - March 2023",
        responsibilities: [
            "Implementing a feature in a cmd tool.",
            "Researching making a custom language server in C#.",
        ]
    },
]