// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-card {
    background: #130f2a;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding: 1.5rem;
    margin: 0 1rem;
}

.experience-card h6 {
    font-size: 1.05rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
}

.duration {
    display: inline-block;
    font-size: 0.7rem;
    font-weight: 400;
    background: rgba(103, 81, 185, 0.5);
    border-radius: 0.3rem;
    padding: 0.4rem 0.6rem;
    margin-bottom: 1.3rem;
}

.experience-card ul li {
    list-style: none;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    position: relative;
}

.experience-card ul li::after {
    content: " ";
    width: 0.5rem;
    height: 0.5rem;
    background-color: #6751b9;
    border-radius: 0.5rem;
    position: absolute;
    left: -1.3rem;
    top: 0.35rem;
}

@media (max-width: 925px){
    .experience-card {
        margin: 0;
    }

    .experience-card h6 {
        font-size: 0.95rem;
    }

    .experience-card ul li {
        font-size: 0.75rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Experience/ExperienceCard/ExperienceCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;IAChB,mCAAmC;IACnC,qBAAqB;IACrB,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI;QACI,SAAS;IACb;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".experience-card {\r\n    background: #130f2a;\r\n    border-radius: 0.65rem;\r\n    border: 1.5px solid #6751b9;\r\n    padding: 1.5rem;\r\n    margin: 0 1rem;\r\n}\r\n\r\n.experience-card h6 {\r\n    font-size: 1.05rem;\r\n    font-weight: 500;\r\n    margin-bottom: 0.7rem;\r\n}\r\n\r\n.duration {\r\n    display: inline-block;\r\n    font-size: 0.7rem;\r\n    font-weight: 400;\r\n    background: rgba(103, 81, 185, 0.5);\r\n    border-radius: 0.3rem;\r\n    padding: 0.4rem 0.6rem;\r\n    margin-bottom: 1.3rem;\r\n}\r\n\r\n.experience-card ul li {\r\n    list-style: none;\r\n    font-size: 0.8rem;\r\n    font-weight: 400;\r\n    margin-bottom: 0.5rem;\r\n    position: relative;\r\n}\r\n\r\n.experience-card ul li::after {\r\n    content: \" \";\r\n    width: 0.5rem;\r\n    height: 0.5rem;\r\n    background-color: #6751b9;\r\n    border-radius: 0.5rem;\r\n    position: absolute;\r\n    left: -1.3rem;\r\n    top: 0.35rem;\r\n}\r\n\r\n@media (max-width: 925px){\r\n    .experience-card {\r\n        margin: 0;\r\n    }\r\n\r\n    .experience-card h6 {\r\n        font-size: 0.95rem;\r\n    }\r\n\r\n    .experience-card ul li {\r\n        font-size: 0.75rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
