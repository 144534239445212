import React from 'react'
import './Contact.css'
import ContactInfoCard from './ContactInfoCard/ContactInfoCard'
import ContactForm from './ContactForm/ContactForm'

const Contact = () => {
  return (
    <section id="contact" className="contact-container">
        <h5>Contact Me</h5>
        
        <div className="contact-content">
            <div style={{flex: 1}}>
                <ContactInfoCard iconUrl="./assets/images/mail.png" link="arne@arnevanlooveren.be" text="arne@arnevanlooveren.be" />
                <ContactInfoCard iconUrl="./assets/images/linkedin.png" link="https://www.linkedin.com/in/arne-van-looveren/" text="LinkedIn" />
                <ContactInfoCard iconUrl="./assets/images/github.png" link="https://github.com/arne-vl" text="Github" />
            </div>
            
            <div style={{flex: 1}}>
                <ContactForm />
            </div>
        </div>
    </section>
  )
}

export default Contact