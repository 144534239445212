import React, { useState } from 'react'
import './Navbar.css'
import MobileNav from './MobileNav/MobileNav';
import { Link, animateScroll as scroll } from 'react-scroll';

const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false);
    const [menuButtonImage, setMenuButtonImage] = useState('./assets/images/menu.png');
    
    const toggleMenu = () => {
        setOpenMenu(!openMenu)
        setMenuButtonImage(openMenu ? './assets/images/menu.png' : './assets/images/close.png');
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

  return (
    <>
        <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />

        <nav className="nav-wrapper">
            <div className="nav-content">
                <img src="./assets/images/logo-white.png" alt="logo" className="logo" onClick={scrollToTop} />

                <ul>
                    <li>
                        <a className="menu-item" onClick={scrollToTop}>Home</a>
                    </li>
                    <li>
                        <Link activeClass="active" smooth spy offset={-180} to="skills">
                            <a className="menu-item">Skills</a>
                        </Link>
                    </li>
                    <li>
                        <Link activeClass="active" smooth spy offset={-180} to="experience">
                            <a className="menu-item">Experience</a>
                        </Link>
                    </li>
                    <Link activeClass="active" smooth spy offset={-180} to="contact">
                        <button className="contact-btn" onClick={() => {}}>
                            Contact Me
                        </button>
                    </Link>
                </ul>

                <button className="menu-btn" onClick={toggleMenu}>
                    <img src={menuButtonImage} alt="menu button" />
                </button>

            </div>
        </nav>
    </>
  )
}

export default Navbar