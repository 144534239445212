import React from 'react'
import './ContactInfoCard.css'

const ContactInfoCard = ({iconUrl, link, text}) => {

    if (link.includes('@')) {
      return (
        <div className="contact-details-card">
          <a href={`mailto: ${link}`} target="_blank">
            <div className="icon">
              <img src={iconUrl} alt={link} />
            </div>
          </a>
          <p>{text}</p>
        </div>
        
      )
    } else {
      return(
        <div className="contact-details-card">
          <a href={link} target="_blank">
            <div className="icon">
              <img src={iconUrl} alt={link} />
            </div>
          </a>
          <p>{text}</p>
        </div>
      )
    }
}

export default ContactInfoCard