// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-details-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #130f2a;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.contact-details-card .icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background: #3d3072;
    margin-bottom: 0.8rem;
}

.icon img {
    width: 2.3rem;
    height: auto;
    object-fit: contain;
}

.contact-details-card p {
    font-size: 0.9rem;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/ContactInfoCard/ContactInfoCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".contact-details-card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n    background: #130f2a;\r\n    border-radius: 0.65rem;\r\n    border: 1.5px solid #6751b9;\r\n    padding: 1.5rem;\r\n    margin-bottom: 2rem;\r\n}\r\n\r\n.contact-details-card .icon {\r\n    width: 4rem;\r\n    height: 4rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 0.5rem;\r\n    background: #3d3072;\r\n    margin-bottom: 0.8rem;\r\n}\r\n\r\n.icon img {\r\n    width: 2.3rem;\r\n    height: auto;\r\n    object-fit: contain;\r\n}\r\n\r\n.contact-details-card p {\r\n    font-size: 0.9rem;\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
