import React, {useRef} from 'react'
import './ContactForm.css'
import emailjs from '@emailjs/browser'

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_ad4ia2p", "template_9e9aujp", form.current, {
        publicKey: "XSN1PYP9-S_P92ETI"
      }
    ).then( () => {
      form.current.reset();
      console.log('Email sent successfully');
    }, (error) => {
      console.log('Email not sent', error);
    });
  };

  return (
    <div className="contact-form-content">
        <form ref={form} onSubmit={sendEmail}>
            <div className="name-container">
                <input type="text" name="firstname" placeholder="First Name" />
                <input type="text" name="lastname" placeholder="Last Name" />
            </div>

            <input type="text" name="email" placeholder="Email" />
            <textarea type="text" name="message" placeholder="Message" rows={6}></textarea>
            <button type="submit" value="Send">Send</button>
        </form>
    </div>
  )
}

export default ContactForm