// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
    margin: 4rem 0;
    position: relative;
}

.contact-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.contact-content {
    display: flex;
    gap: 3rem;
}

@media (max-width: 925px) {
    .contact-content {
        flex-direction: column-reverse;
    }

    .contact-container h5 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,8BAA8B;IAClC;;IAEA;QACI,iBAAiB;QACjB,mBAAmB;IACvB;AACJ","sourcesContent":[".contact-container {\r\n    margin: 4rem 0;\r\n    position: relative;\r\n}\r\n\r\n.contact-container h5 {\r\n    font-size: 1.5rem;\r\n    font-weight: 600;\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.contact-content {\r\n    display: flex;\r\n    gap: 3rem;\r\n}\r\n\r\n@media (max-width: 925px) {\r\n    .contact-content {\r\n        flex-direction: column-reverse;\r\n    }\r\n\r\n    .contact-container h5 {\r\n        font-size: 1.3rem;\r\n        margin-bottom: 2rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
