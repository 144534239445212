// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-container {
    margin: 4rem 0;
    position: relative;
}

.experience-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
}

.arrow-left,
.arrow-right {
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.65rem;
    border: 1.5px solid #6751b9;
    background: #130f2a;
    position: absolute;
    top: 55%;
    z-index: 2;
    cursor: pointer;
}

.arrow-left {
    left: -2rem;
}

.arrow-right {
    right: -2rem;
}

@media (max-width: 1025px) {
    .arrow-left {
        left: 0rem;
    }

    .arrow-right {
        right: 0rem;
    }
}

@media (max-width: 925px) {
    .experience-container h5 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .arrow-left {
        left: -1rem;
    }

    .arrow-right {
        right: -1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Experience/Experience.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;;IAEI,aAAa;IACb,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,iBAAiB;QACjB,mBAAmB;IACvB;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,YAAY;IAChB;AACJ","sourcesContent":[".experience-container {\r\n    margin: 4rem 0;\r\n    position: relative;\r\n}\r\n\r\n.experience-container h5 {\r\n    font-size: 1.5rem;\r\n    font-weight: 600;\r\n    margin-bottom: 3rem;\r\n}\r\n\r\n.arrow-left,\r\n.arrow-right {\r\n    width: 2.2rem;\r\n    height: 2.2rem;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border-radius: 0.65rem;\r\n    border: 1.5px solid #6751b9;\r\n    background: #130f2a;\r\n    position: absolute;\r\n    top: 55%;\r\n    z-index: 2;\r\n    cursor: pointer;\r\n}\r\n\r\n.arrow-left {\r\n    left: -2rem;\r\n}\r\n\r\n.arrow-right {\r\n    right: -2rem;\r\n}\r\n\r\n@media (max-width: 1025px) {\r\n    .arrow-left {\r\n        left: 0rem;\r\n    }\r\n\r\n    .arrow-right {\r\n        right: 0rem;\r\n    }\r\n}\r\n\r\n@media (max-width: 925px) {\r\n    .experience-container h5 {\r\n        font-size: 1.3rem;\r\n        margin-bottom: 2rem;\r\n    }\r\n\r\n    .arrow-left {\r\n        left: -1rem;\r\n    }\r\n\r\n    .arrow-right {\r\n        right: -1rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
